@import './mixin.scss';
@import './variable.scss';

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: $roboto
  }
  .linkText{
    @include link-text;
  }
  /* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #555 ; 
  border-radius: 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.color-primary{
  color: $primary !important;
}
.color-secondary{
  color: $deepBlue;
}
.colorRed{
  color: $red;
}
.f-500{
  font-weight: 500;
}
.m-auto{
  margin: auto;
}
.mx-auto{
  margin: 0 auto;
}
.my-auto{
  margin: auto 0;
}
.mt-1 {
  margin-top: 1rem ;
}
.mt-2 {
  margin-top: 2rem ;
}
.mt-3 {
  margin-top: 3rem ;
}
.mt-4 {
  margin-top: 4rem ;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mr-1{
  margin-right: 4px;
}
.mr-2{
  margin-right: 8px;
}
.mr-3{
  margin-right: 12px;
}
.mr-4{
  margin-right: 24px;
}
.ml-1{
  margin-left: 4px;
}
.ml-2{
  margin-left: 8px;
}
.ml-3{
  margin-left: 12px;
}
.ml-4{
  margin-left: 24px;
}

.px-1{
  padding: 0 4px ;
}
.px-2{
  padding: 0 8px ;
}
.px-3{
  padding: 0 12px ;
}
.px-4{
  padding: 0 24px ;
}

.py-1{
  padding:  4px 0;
}
.py-2{
  padding:  8px 0;
}
.py-3{
  padding:  12px 0;
}
.py-4{
  padding:  24px 0;
}

.card-selected{
  border: 1px solid $deepBlue !important;
}
.display-none{
  display: none;
}
.display-hidden{
  visibility: hidden;
  pointer-events: none;
}
.text-center {
  text-align: center;
}

.card-data-spacing {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.d-flex{
  display: flex;
}
.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-col{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.flexAlign-center{
  display: flex;
  align-items: center;
}
.container-block{
  // padding: 25px;
  padding: 50px 25px 25px;
}
.flexBox{
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 20px;
}
.ant-spin-spinning{
  color: black;
  margin-top: 10%;
}
.w-100{
  width: 100%;
}
.w-75{
  width: 75%;
}
.w-50{
  width: 50%;
}
.icon-space {
  padding: 1rem;
  cursor: pointer;
}
.image-text{
  background-image: url('../../../public/images/mobWrap.png');
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // margin-top: 200px;
  // font-size: 120px;
  // text-align: center;
  // font-weight: bold;
  // text-transform: uppercase;
  font-family: 'Inter', 'helvetica neue',
              helvetica, arial, sans-serif;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  background-position: 0px 78px;
  background-size: 100%;
}
.common-container{
  padding: 0 150px;
}

.text-left{
  text-align: left !important;
}
.fold-gap{
  margin-top: 11rem !important;
}
@media screen and (max-width: 992px){
  .fold-gap{
    margin-top: 8rem !important;
  }
  .common-container{
    padding: 0 24px !important;
  }
}
.highlightBlack{
  color: black;
}
.primary_size{
  font-size: 1rem;
}
.d-block{
  display: block;
}

.box_gap{
  margin-bottom: 4rem;
}
.para_gap{
 line-height: 1.65rem;
 margin-bottom: 1.45rem;
}