$poppins : "Poppins",
sans-serif;

.second_fold_v1 {
  width: 100%;
  .container {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    .second_wrapper {
      position: relative;
      margin-top: -25%;
      .video_placeholder {
        max-width: 1200px;
        margin: 0 auto;
        border-radius: 12px;
        video {
          width: 100%;
          height: 100%;
          border-radius: 12px;
        }
      }
      .second_text {
        font-size: 20px;
        line-height: 1.5;
        color: #fff;
        font-weight: 400;
        font-family: $poppins;
        margin-top: 22px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .second_fold_v1 {
    .container {
      max-width: 100%;
      padding: 0 20px;
      .second_wrapper {
        margin-top: -30%;
        .video_placeholder {
          width: 100%;
          padding: 0 20px;
          img {
            width: 100%;
          }
        }
        .second_text {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .second_fold_v1 {
    .container {
      max-width: 100%;
      padding: 0 20px;
      .second_wrapper {
        margin-top: -30%;
      }
    }
  }
}
