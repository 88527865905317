$poppins : "Poppins",
sans-serif;

.third_fold_v1 {
    .container {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;

        .third_wrapper {
            padding: 40px 0 60px;

            .title_third {
                color: #fff;
                font-family: $poppins;
                font-size: 30px;
                font-weight: 600;
            }

            .sub_title {
                padding: 30px 0 10px;
                font-family: $poppins;
                font-size: 26px;
                line-height: 1.1;
                font-weight: 600;
                color: #fff;
            }

            .text {
                color: #fff;
                font-size: 20px;
                line-height: 1.5;
                font-weight: 400;
                font-family: $poppins;
            }

            .before_after {
                display: flex;
                gap: 28px;
                padding-top: 40px;
                .image_wrapper {
                    width: 934px;
                    display: flex;
                    align-items: center;
                    gap:28px;
                    img {
                        width: 100%;
                    }
                }

            }

        }
    }
}

@media screen and (max-width:1200px){
    .third_fold_v1 {
        .container {
            max-width: 100%;
            padding: 0 20px;
            .third_wrapper{
                width: 100%;
                .before_after{
                    width: 100%;
                    .image_wrapper{
                        width: 46%; 
                        img{
                            width: 100%;
                        }
                        &.image_full{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:600px) {
    .third_fold_v1 {
        .container {
            .third_wrapper {
                .title_third {
                    font-size: 20px;
                }

                .sub_title {
                    font-size: 18px;
                }

                .text {
                    font-size: 16px;
                }
            }
        }
    }
}