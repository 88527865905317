@import '../../assets/scss/mixin.scss';
@import '../../assets/scss/variable.scss';
.info_under_logo {
    div:last-child {
        text-align: left;
    }
}
.email_col {
    float: right;
    width: 41%;
    label {
        font-weight: 700;
        font-size: 1rem;
    }
    .email_input {
        width: 350px;
        height: 40px;
        background-color: #f3f3f3;
        border-radius: 0;
    }
    .email_btn {
        margin: 0 18px;
        height: 40px;
        background-color: #f3f3f3;
        border-radius: 0;
    }
}
.country_box {
    align-items: center;
    .country_list {
        width: 40%;
        gap: 3% !important;
        .country_text {
            font-size: 1rem;
        }
    }
    .country_line {
        background-color: black;
        height: 1px;
        // width: 60%;
        flex-grow: 1;
    }
}
.address_text {
    text-align: left;
    white-space:pre;
    line-height: 35px;
}
.footer {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    .media_icon_container {
        margin-left: auto;
        margin-bottom: 5px;
        .social_media_icons {
            height: 25px;
            gap: 8px;
        }
    }
}
.company_row{
    .comp_info_text{
        font-size: 1rem;
    }
}
.link_text{
    color: black !important;
}
.footer_list_text{
    cursor: pointer;
}
.call_box{
    .ant-row{
        background-color: #F8F8F8;
        background: url('../../../public/images/wrap.svg');
        background-repeat: no-repeat;
        height: 240px;
        padding: 0 50px;
        background-size: 100%;
        .call_box_title{
            font-weight: 700;
            color: $white;
            font-size: 2.3rem;
        }
        .inner_call_box{
            border-radius: 4px;
            height: 45px;
        }
        .btn_call_box{
            background-color: $primary;
            color: white;
            border-radius: 2px;
        }
    }
}
.hr_bar {
    background-color: $primary;
    height: 1px;
}

@media screen and (max-width: 1440px){
    .email_col{
        width:43%  !important;
    }
    .call_box {
		.ant-row{background-size: auto !important;}
	}
}
@media screen and (max-width: 1536px){
    .email_col{
        width:42% ;
    }
}
@media screen and (max-width:2048px) and (min-width:1700px) {
    .under_logo_text,.comp_info_text,.address_text,.country_text,.footer_list_text ,.company_text {
        font-size: 1.2rem !important;
    }
    .info_under_logo{
        img{
            height: 8vh;
        }
    }
    .info_space{
        span{font-size: 1.2rem;}
    }
}
@media screen and (max-width:992px) {
    .call_box{
        padding: 0 24px !important;
        .ant-row{
            height: 200px !important;
            padding: 0 30px !important;
            background-position: center top !important;
            background-size: auto !important;
            .call_box_title{
                font-size: 20px !important;
                font-weight: 700;
                text-align: center;
            }
            .call_input_col{
                flex-direction: column;
                width: 100%;
                .inner_call_box{
                    height: 45px !important;
                }
                .btn_call_box{
                    margin-top: 10px;
                    width: 100%;
                }
            }
        }
    }
}