$poppins : "Poppins",
sans-serif;

.sixth_fold_v1 {
    background: linear-gradient(180deg, #1B1A3D 0%, rgba(28, 27, 62, 0) 100%);
    height: 600px;
    padding:70px 0;
    .container {
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;

        .sixth_wrapper {
            background: rgba(243, 243, 248,0.15);
            border-radius: 24px;
            position: relative;
            padding:60px;
            .signup {
                text-align: center;
                .signup_title{
                    color:#fff;
                    font-size: 54px;
                    font-weight: 700;
                    font-family: $poppins;
                }
                .signup_text{
                    color:#fff;
                    font-size: 24px;
                    font-weight: 400;
                    font-family: $poppins;
                    padding-top: 16px;
                    max-width: 425px;
                    margin: 0 auto;
                    width: 100%;
                }
                form {
                    max-width: 820px;
                    margin:40px auto;
                    width: 100%;
                    .email_wrapper{
                        display: flex;
                        align-items: center;
                        gap:18px;
                        .email_input{
                          max-width: 585px;
                          width: 100%;
                          background: rgba(243, 243, 248,0.15);
                          padding: 0 20px;
                          height: 55px;
                          border:1px solid rgba(255,255,255,0.5);
                          border-radius: 16px;
                          color:rgba(255,255,255, 0.4);
                          font-size: 18px;
                          &::placeholder{
                            color:rgba(255,255,255, 0.4);
                            font-size: 18px;
                            font-family: $poppins;
                          }
                        }
                        .button_section {
                            background: #fff;
                            width: 216px;
                            padding:14px 0!important;
                            height: unset;
                            border-radius: 16px;
                            text-transform: uppercase;
                            border:none;
                                font-size: 18px;
                                color: #1B1A3D;
                                letter-spacing: 2px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 14px;
                                font-family: $poppins;
                                font-weight: 600;
                                cursor: pointer;
                        }

                    }
                    .subscribe{
                        font-size: 16px;
                        color:#fff;
                        text-align: center;
                        font-weight: 400;
                        padding-top: 40px;
                        font-family: $poppins;
                    }
                   
                }
            }

        }
    }

}

@media screen and (max-width:1200px){
    .sixth_fold_v1{
        .container{
            max-width: 100%;
            padding:0 20px;
            .sixth_wrapper{
                padding:30px;
                .signup{
                    .signup_title{
                        font-size: 22px;
                    }
                    .signup_text{
                        font-size: 16px;
                        max-width: 100%;
                    }
                    form{
                        max-width: 100%;
                        .subscribe{
                            font-size: 14px;
                        }
                        
                    }
                }
            }
        }
    }
}


@media screen and (max-width:992px){
    .sixth_fold_v1{
        .container{
            max-width: 100%;
            padding:0 20px;
            .sixth_wrapper{
                .signup{
                    
                    form{
                        max-width: 100%;
                        .email_wrapper{
                            flex-direction: column;
                            .email_input{ max-width: 100%;}
                            .button_section{
                                width: 100%;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}