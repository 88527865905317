.policy_page, .terms_page{
    padding: 80px 300px 80px 100px;
    ul{
        margin-left: 24px;
    }
}
.terms_page{
    padding: 80px 100px;
    ul{
        line-height: 2rem;
    }
}

@media screen and (max-width:992px) {
    .policy_page, .terms_page{
        padding: 45px;
        p{
            font-size: 1rem;
        }
        ul{
            margin-left: 24px;
            li{
                font-size: 1rem;
            }
        }
    }
}
