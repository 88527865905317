@import '../../assets/scss/mixin.scss';
@import '../../assets/scss/variable.scss';
.nav-wrapper {
    width: 100%;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom:1px solid $primary;
    z-index: 9;
    height: 110px;
  }
  
//   .grad-bar {
//     width: 100%;
//     height: 5px;
//     background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
//     background-size: 400% 400%;
//         -webkit-animation: gradbar 15s ease infinite;
//       -moz-animation: gradbar 15s ease infinite;
//       animation: gradbar 15s ease infinite;
//   }
  
  /* NAVIGATION */
  
  .navbar {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    min-height: 50px;
    overflow: hidden;
    padding: 5px 24px ;
    height: 65px
  }
  
  .navbar img {
    height: 7vh;
    width: auto;
    justify-self: start;
    margin-left: 20px;
  }
  
  .navbar ul {
    list-style: none;
    display: flex;
    grid-template-columns: repeat(6, 1fr);
    justify-self: end;
    align-items: center;
    justify-content: flex-end;
    width: 55%;
    color: $grey;
    font-weight: 500;
    gap: 28px;
    
  }
  
  .nav-item a {
    color: #000;
    font-size: 0.9rem;
    font-weight: 400;
    text-decoration: none;
    transition: color 0.3s ease-out;
  }
  
  .nav-item a:hover {
    color: #3498db;
  }
  .nav-item{
    cursor: pointer;
  }
  
  /* SECTIONS */
  
  .headline {
    width: 100%;
    height: 50vh;
    min-height: 350px;
    background: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.7)), url('https://images.unsplash.com/photo-1435224668334-0f82ec57b605?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fA%3D%3D&w=1000&q=80');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .features {
    width: 100%;
    height: auto;
    background-color: #f1f1f1;
    display: flex;
    padding: 50px 20px;
    justify-content: space-around;
  }
  
  .feature-container {
    flex-basis: 30%;
    margin-top: 10px;
  }
  
  .feature-container p {
    color: #000;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 15px;
  }
  
  .feature-container img {
    width: 100%;
    margin-bottom: 15px;
  }
  
  /* SEARCH FUNCTION */
  
  #search-icon {
    font-size: 0.9rem;
    margin-top: 3px;
    margin-left: 15px;
    transition: color 0.3s ease-out;
  }
  
  #search-icon:hover {
    color: #3498db;
    cursor: pointer;
  }
  .active-nav{
    color: $primary;
    font-weight: 600;
  }
  .search {
    transform: translate(-35%);
    -webkit-transform: translate(-35%);
    transition: transform 0.7s ease-in-out;
    color: #3498db;
  }
  
  .no-search {
    transform: translate(0);
    transition: transform 0.7s ease-in-out;
  }
  
  .search-input {
    position: absolute;
    top: -4px;
    right: -125px;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.6s ease;
  }
  
  .search-active {
    opacity: 1;
    z-index: 0;
  }
  
  input {
    border: 0;
    border-left: 1px solid #ccc;
    border-radius: 0; /* FOR SAFARI */
    outline: 0;
    padding: 5px;
  }
  
  /* MOBILE MENU & ANIMATION */
  
  .menu-toggle .bar{
    width: 25px;
    height: 3px;
    background-color: #3f3f3f;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .menu-toggle {
    justify-self: end;
    margin-right: 25px;
    display: none;
  }
  .mob_btn_container{
    display: none;
  }
  .menu-toggle:hover{
    cursor: pointer;
  }
  
  #mobile-menu.is-active .bar:nth-child(2){
    opacity: 0;
  }
  
  #mobile-menu.is-active .bar:nth-child(1){
    -webkit-transform: translateY(8px) rotate(45deg);
    -ms-transform: translateY(8px) rotate(45deg);
    -o-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }
  
  #mobile-menu.is-active .bar:nth-child(3){
    -webkit-transform: translateY(-8px) rotate(-45deg);
    -ms-transform: translateY(-8px) rotate(-45deg);
    -o-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
  }
  
  /* KEYFRAME ANIMATIONS */
  
  @-webkit-keyframes gradbar {
      0% {
          background-position: 0% 50%
      }
      50% {
          background-position: 100% 50%
      }
      100% {
          background-position: 0% 50%
      }
  }
  
  @-moz-keyframes gradbar {
      0% {
          background-position: 0% 50%
      }
      50% {
          background-position: 100% 50%
      }
      100% {
          background-position: 0% 50%
      }
  }
  
  @keyframes gradbar {
      0% {
          background-position: 0% 50%
      }
      50% {
          background-position: 100% 50%
      }
      100% {
          background-position: 0% 50%
      }
  }
  .signin_btn_mob{
    display: none;
  }
  /* Media Queries */
  
    /* Mobile Devices - Phones/Tablets */
  
  @media only screen and (max-width: 720px) { 
    .features {
      flex-direction: column;
      padding: 50px;
    }
    .nav-wrapper{
      position: fixed;
      left: 0;
      z-index: 99;
    }
    /* MOBILE HEADINGS */
    
    h1 {
      font-size: 1.9rem;
    }
    
    h2 {
      font-size: 1rem;
    }
    
    p {
      font-size: 0.8rem;
    }
    
    /* MOBILE NAVIGATION */
       
    .navbar ul {
      display: flex;
      flex-direction: column;
      position: fixed;
      justify-content: start;
      top: 69px;
      background-color: #fff;
      width: 100%;
      height: 100%;
      transform: translate(101%);
      text-align: center;
      overflow: hidden;
      left: 0;
      gap: 8px;
      .signin_btn{
        font-size: 1.3rem;
        height: 45px;
      }
    }
    
    .navbar li {
      padding: 15px;
      font-size: 1.3rem;
    }
    
    .navbar li:first-child {
      margin-top: 50px;
    }
    
    .navbar li a {
      font-size: 1rem;
    }
     
    .menu-toggle, .bar {
      display: block;
      cursor: pointer;
    }
    
    .mobile-nav {
    transform: translate(0%)!important;
  }
    
    /* SECTIONS */
    
    .headline {
      height: 20vh;
    }
      
    .feature-container p {
      margin-bottom: 25px;
    }
    
    .feature-container {
      margin-top: 20px;
    }
    
    .feature-container:nth-child(2) {
      order: -1;
    }
    
    /* SEARCH DISABLED ON MOBILE */
    
    #search-icon {
      display: none;
    }
    
    .search-input {
    display: none;
   }
   .navbar img{
    height: 50px !important;
    width: auto;
    justify-self: start;
    margin-left: 0px;
   }
   .mob_btn_container{
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
   }
   .signin_btn_mob{
    display: block !important;
    height: 35px !important;
    width: max-content;
    font-size: 10px;
   }
// .nav_box{
//   height: 110px !important;
//   .navbar{
//     height: 70px;
//   }
// }

  }
  .signin_btn{
    color: $white;
    border-color: $primary;
    height: 40px;
    background-color: $primary;
    font-weight: 700;
    &:hover{
      color: $primary !important;
      background-color: $white;
    }
  }
  .nav_box {
		width: 100%;
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 0;
		z-index: 999;
		background-color: #fff;
    height: 110px;
	}
  @media only screen and (max-width: 1400px){
    .navbar ul{
      font-size: 0.8rem;
    }
    .signin_btn{
      height: 30px;
      font-size: 0.8rem;
    }
  }
  @media screen and (max-width:994px){
    .navbar img {
      height: 100%;
      width: auto;
      justify-self: start;
      margin-left: 20px;
    }    
    .navbar ul{
    width: 95%;
    }
  }