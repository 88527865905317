@import '../../assets/scss/variable.scss';
.site-header{
    // padding: 0 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    background-color: $primary;
    .site-btn-active{
        background-color: $white;
        color: $primary;
    }
    .site-btn-inactive{
        color: $white;
        background-color: rgba(255, 255, 255, 0.521);
    }
}
@media screen and (max-width:994px){
    .site-header{
        justify-content: center;
    }
}