@import "../assets/scss/mixin.scss";
@import "../assets/scss/variable.scss";
.main-page{
	margin-top: 6rem;
	.title_text {
		font-size: 3.5rem;
		margin-bottom: 0 !important;
		font-family: 'Inter',sans-serif;
		font-style: normal;
		font-weight: 700;
		margin-top: 0 !important;
		text-transform: capitalize;
	}
	.title_box {
		margin-top: 6rem;
		padding: 0 18%;
		.title_text{
			font-size: 4rem;
		}
		.title_text_underheading{
			font-weight: 400;
		}
		.try_btn{
			color: $primary;
			font-size: 1rem;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			width: 335px;
			&:hover{
					// color: white;
					// transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
					border-color: $primary;
					// box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
					box-shadow: 0px 0px 4px 0px rgb(85, 113, 237) ;
					transform: scale(1.1);
					// transition-delay:0s;
					  
				}
			
			
		}
		.text-icon-space{
			display: flex;
			align-items: flex-start;
			flex-direction: column;
		}
	}
	
	.image_collage{
		// justify-content: space-around;
		// // gap: 1%;
		padding: 0 24px;
		margin-top: 4rem;
		// margin: 8rem auto 0;
		// .web_img_single,.group_img_cont{
		// 	height: 60vh;
		// }
		// .web_img_group{
		// 	object-fit: contain;
    	// 	// width: 12vw;
		// 	height: 29vh;
		
		// }
		// .group_img_cont{
		// 	gap: 8px;
		// 	.c2{
		// 		height: 35vh;
		// 	}
		// 	.c3{
		// 		height: 24vh;
		// 	}
		// }

		.webCollage_img,.mobCollage_img{
			max-width: 100%;
		}
	}
	
	.clients_box{
		.primary_size{
			width: 50%;
			display: block;
			margin: 0 auto;
		}
		.client_logo_container{
			flex-wrap: wrap;
			justify-content: center;
			// gap: 28px;
			gap: 26px;
		}
		.logo_cards{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 10vw;
			height: 10vh;
		}
	}

	.product_box{
		.productTitle_img{
			object-fit: contain;
			height: 11vh;
			width: 50%;
		}
		.blueLine{
			width: 14%;
			border: 1px solid $primary;
		}
		.prod_subText{
			font-style: italic;
			font-weight: 300;
			color: #5571ED;
			font-size: 1.15rem;
			align-items: center;
		}
		.prod_title_description{
			display: block;
			font-size: 1rem;
		}
		.prod_col{
			height: 75vh;
			.prod_gif{
				height: 50vh;
			}
			
		}
		.prod_brands{
			border-top: 1px solid black;
			border-bottom: 1px solid black;
		}
	}

	.partner_box{
		.partner_title{
			font-size: 3rem;
			margin-bottom: 0;
			font-weight: 700;
		}
		.benifit_card{
			position: relative;
			text-align: left;
			.backLay_num{
				font-size: 6rem;
				font-weight: 600;
				position: absolute;
				z-index: -1;
				top: -30px;
				left: 0;

			}
			.backLay_num:after{
				content: " ";
				position: absolute;
				width: 90%;
				left: 7px;
				top: 99px;
				height: 20px;
				z-index: -1;
				box-shadow: 0px -4px 7px 0px #888;
			  }
			//   .benifit_title{
			// 	height: 10vh;
			//   }
			.benifit_text_container{
				background-color: $white;
				.benifit_title {
					font-size: 1.1rem;
				}
				.benifit_description {
					font-size: 1rem;
				}
			}
			.backLay_num_1{
				color: #7CC6B9;
			}
			.backLay_num_2{
				color: #A294C9;
			}
			.backLay_num_3{
				color: #62AED0;
			}
			.num_mob{
				display: none;
			}
		}
		.benifit_title_mob{
			display: none !important;
		}
	}

	.useCase_box{
		.useCase_title{
			margin: 0 auto;
			font-size: 2.8rem;
			margin-top: 0;
			width: 50%;
		}
		.custom_segment{
			background-color: transparent;
			.ant-segmented-item-label{
				font-size: 1.2rem !important;
			}
			.ant-segmented-thumb{
				background-color: #5973e76c !important;
			}
			.ant-segmented-group{
				gap: 25px;
			}
			.ant-segmented-item{
				height: 45px;
				@include flex-center;
			}
			.ant-segmented-item-selected{
				background-color: $primary;
				color: $white;
				font-weight: 600;
			}
		}

		.case_row{
			.ant-col{
				height: 40vh;
			}
			.case_card{
				height: 100%;
				
				.ant-card-body{
					height: 100%;
					width: 100%;
				}
				.num_circle{
					height: 60px;
					width: 60px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1.8rem;
					color: $white;
					font-weight: 500;
					&:nth-child(1){
						background-color:  #D9CFFF;
											}
					&:nth-child(2){
						background-color:  #FBD7A3;
											}
					&:nth-child(3){
						background-color:  #AAD8DB;
											}
					&:nth-child(4){
						background-color:  #E4B9F5;
											}
					&:nth-child(5){
						background-color:  #ffa9a9;
											}
				}
				.case_card_text{
					font-weight: 500;
				}
			}
		}
		.slider_box{
			.slider_line{
				border-bottom: 3px solid $primary;
			}
			.slider_prod{
				display: flex;
				align-items: center;
				// gap: 6vw;
				justify-content: space-between;
				// padding: 15px 24px;
				position: relative;
				// margin-top: 1.5rem;
				.noraml_slider_box{
					padding: 24px;
				}
				.slider_prod_img{
					height: 2.4vh;
					cursor: pointer;
					// position: relative;
					opacity: 0.4;
				}
				.active_prod{
					transform: scale(1.2);
					opacity: 1;
				}
				.active_prod_div{
					position: relative;
				}
				.active_prod_div::after{
					content: "";
					position: absolute;
					left: 50%;
					width: 0;
					height: 0;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-top: 15px solid #5571ED;
					clear: both;
					top: 0;
					transform: translate(-50%, 0);
				}
			}
		}
		.slide_box_mob {
			display: none;
		}
	}


	.how_works{
		.custom_menu{
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: space-between;
			padding: 20px 0;
			align-items: flex-start;
			li{
				list-style: none; 
				text-align: center;
				border-bottom: 2px solid transparent;
				cursor: pointer;
				position: relative;
				padding: 10px 0;
				height: 6vh;
				&:hover{
					border-bottom: 2px solid $primary;
					// text-decoration: underline;
				}
				img{
					height: 2.5vh;
				}
				opacity: 0.4;
				
			 }
			 .active_item{
				transform: scale(1.35);
				opacity: 1;
				&:hover{
					border-bottom: 2px solid transparent;
				}
			 }
			 .active_item:after {
				content: "";
				text-decoration: none;
				position: absolute;
				width: 40%;
				left: 0;
				top: 40px;
				border-bottom: 2px solid  $primary;
			}
			}
		.video_player{
			border: 12px solid #F9F9F9;
			border-radius: 6px;
		}
		.size_imgs{
			height: 9vh;
		}
	}
    .call_box{
		.ant-row{
			background-color: #F8F8F8;
            background: url('../../public/images/wrap.svg');
            background-repeat: no-repeat;
			height: 240px;
			padding: 0 50px;
			background-size: 100%;
			.call_box_title{
				font-weight: 700;
                color: $white;
				font-size: 2.3rem;
			}
			.inner_call_box{
				border-radius: 4px;
				height: 45px;
			}
			.btn_call_box{
				background-color: $primary;
				color: white;
                border-radius: 2px;
			}
		}
	}
    .hr_bar {
		background-color: $primary;
		height: 1px;
	}

	.collage_mob{
		display: none;
	}
}

@media screen and (max-width: 992px) {
	// @media screen and (max-width: 767px) {
	//media queries here for mobile

	.main-page {
		margin-top: 10rem;
		.title_box {
			margin-top: 2rem;
			.try_btn{
				width: 45%;
				font-size: 0.9rem;
				img{
					height: 10px;
				}
				
			}
			.title_text{
				font-size: 1.5rem !important;
				background-position: 40px 96px;
			}
			.title_text_underheading{
				font-size: 1rem;
			}
			.title_img_box{
				// flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 10px;
				width: 100%;
				img{
					height: 28px;
				}
				span{
					font-size: 12px;
				}
				.text-icons-container{
				flex-direction: column;
				.text-icon-space{
					align-items: center;
					text-align: center !important;
					margin-top: 5px;
					margin-left: 0 !important;
				}
				}
			}
			.image-text{
				background-image: url('../../public/images/mobWrap.png');
				background-repeat: repeat;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				// margin-top: 200px;
				// font-size: 120px;
				// text-align: center;
				// font-weight: bold;
				// text-transform: uppercase;
				font-family: 'Inter', 'helvetica neue',
							helvetica, arial, sans-serif;
				// font-weight: 900;
				-webkit-font-smoothing: antialiased;
				background-position: left;
				background-size: 100%;
				font-size: 1.5rem !important;
			}
			
		}

		.title_text {
			font-size: 1.8rem !important;
		}

		.title_text_underheading {
			padding: 0 10%;
		}

		.light_title_text {
			font-size: 1.5rem;
			line-height: 2rem;
			width: 90% !important;
		}

		.bottom_box {
			padding: 0 24px;
			display: flex;
			flex-direction: column;
			gap: 16px;
			justify-content: center;
			align-items: center;
			margin-top: 8rem;
			.bottom-cards {
				background-color: #fbfbfd;
				display: flex;
				flex-direction: column;
				width: 100%;
				padding: 15px 8px 0;
				img {
					margin-top: 20px;
					width: 100%;

					object-fit: contain;
				}
			}
		}

		.clients_box{
			.client_heading{
				font-size: 1.6rem !important;
			}
			.primary_size{
				width: 100%;
			}
			.globe_text{
				font-size: 1.2rem !important;
			}
			.logo_cards{
				width: 40vw !important;
				img{
					height: auto !important;
					object-fit: contain;
					max-width: none !important;
				}
			}
		}
		.product_box{
			.productTitle_img{
				height: 4vh;
				width: 100% !important;
			}
			.underProd_box{
				width: 55%;
				.blueLine{
					width: 7%;
				}
				span{
					font-size: 0.85rem !important;
				}
			}
			.prod_title_description{
				width: 100%;
				font-size: 0.95rem;
			}
			.prod_col{
				height: 40vh ;
				
				 .prod_gif{
					height: 15vh !important;
				 }
				 .prod_col_card{
					width: 90%;
					.prod_brands {
						height: 72px;
						flex-direction: column;
						text-align: left;
						gap: 6px;
						justify-content: start;
						img{
							align-self: flex-start;
						}span{
							font-size: 0.7rem;
							align-self: flex-start;
						}
					}
				 }
				}
		}

		.partner_box{
			.partner_title {
				font-size: 1.8rem !important;
			}
			.partner_subTitle {
				font-size: 1.4rem;
			}
			.benifit_title {
				font-size: 1rem !important
			}
			.color_divider{
				width: 10vw !important;
			}
			.benifit_title_web{
				display: none ;
			}
			.benifit_title_mob{
				display: block !important;
			}
			.benifit_description {
				font-size: 0.9rem !important;
			}
			.num_web{
				display: none;
			}
			.num_mob{
				display: block !important;
				font-size: 5rem;
				font-weight: 600;
			}
			.num_mob:after{
				content: " ";
				position: absolute;
				width: 28%;
				left: 5px;
				top: 88px;
				height: 30px;
				z-index: 99;
				box-shadow: 0px -5px 7px -8px #888;
				background-color: white;
			  }
			.benifit_card {
				flex-direction: row;
				justify-content: space-between;
				height: 28vh;
				align-items: flex-start;
				.benifit_text_container {
					width: 66%;
					margin-top: 40px;
				}
			}
		}

		.useCase_box{
			.useCase_title{
				font-size: 1.8rem !important; 
    			width: 100%;
			}
			.useCase_subtitle{
				width: 100%;
				// font-size: 1.5rem;
			}
			.custom_segment {
				width: 100%;
				.ant-segmented-group{
					gap:8px ;
				}
				.ant-segmented-item{
					height: 35px;
					.ant-segmented-item-label{
						font-size: 12px;
					}
				}
			}
			.case_row{
				row-gap: 25px !important;
				width: 100%;
				 .ant-col{
					width: 100%;
					height: 25vh;
					.ant-card-body{
						padding: 15px !important;
					}
				 }
				 .num_circle{
					height: 45px !important;
					width: 45px !important;
					font-size: 1.5rem !important;
				 }
				//  .case_card_text{
				// 	font-size: 1rem;
				//  }
				}
				.slider_box .slider_prod .slider_prod_img {
					height: 1.25vh !important;
					// cursor: pointer;
				}
				.slider_box {
					display: none;
				}

				.slide_box_mob{
					display: flex !important;
					width: 100%;
					overflow-y: auto;
					gap: 16px;
					align-items: center;
					padding: 5px;
					background-color: rgb(235 235 235 / 42%);
					.mob_noraml_slider_box{
						padding: 9px;
						height: 4.2vh;
						border-bottom: 2px solid transparent;
					}
					img{
						height: 100%;
					}
					.mob_active_prod_div{
						border-bottom: 2px solid $primary;
					}
					.mob_active_prod{
						transform: scale(1.1);
						opacity: 1 !important;
					}
					.mob_slider_prod_img{
						opacity: 0.4;
					}
				}
				.slide_box_mob::-webkit-scrollbar {
					width: 0px !important;
					height: 0px !important;
					background: transparent !important; /* make scrollbar transparent */
				}
				.slide_box_mob::-webkit-scrollbar-track {
					background: transparent !important; 
				  }
				  .slide_box_mob::-webkit-scrollbar-thumb {
					background: transparent ; 
					border-radius: 0
				  }
				  
				  /* Handle on hover */
				  .slide_box_mob::-webkit-scrollbar-thumb:hover {
					background: none; 
				  }
		}

		.how_works {
			.work_header_cont{
				flex-direction: column;
				gap: 6px;
				.work_subTitle{
					width: 100%;
				}
				.x_imgs{
					align-self: flex-end;
				}
			}
			.custom_menu{
				flex-direction: row;
				overflow-x: auto;
				gap: 32px;
				padding-left: 25px;
				li{
					&:hover{
						border-bottom: none;
						// text-decoration: underline;
					}
					padding: 5px 0;
					img{
						height: 15px;
					}
				}
				.active_item:after{
					top: 24px;
				}
			}
			.video_player{
				height: auto !important;
				// border: none;
				// border-radius: 0;
				// aspect-ratio: 16/9 !important;
			}
			.size_imgs{
				height: 7vh;
			}
			.video_row{
				margin-top: 1rem !important;
			}
		}
	}
	.company_row {
		padding: 0 30px !important;
		.email_col {
			width: 100% !important;
			align-items: center;
			div {
				width: 100%;
			}
			.email_div {
				flex-direction: column;
				width: 100%;
				.email_input,
				.email_btn {
					width: 100% !important;
				}
				.email_btn {
					margin: 10px 0;
				}
			}
		}
		.country_line {
			display: none;
		}
		.country_list {
			gap: 4% !important;
			width: 100% !important;
		}
	}
	.footer {
		padding: 0 24px;
		.media_icon_container {
			margin: 0 auto 5px;
		}
		.footer_end {
			flex-direction: column;
			.footer_list {
				gap: 6px !important;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
			}
			.company_text {
				margin-top: 15px;
			}
		}
	}
.review_inner_box{
	padding: 0 24px;
}

.call_box{
	padding: 0 24px !important;
	.ant-row{
		height: 200px !important;
		padding: 0 30px !important;
		background-position: center top !important;
		background-size: auto !important;
		.call_box_title{
			font-size: 20px !important;
			font-weight: 700;
			text-align: center;
		}
		.call_input_col{
			flex-direction: column;
			width: 100%;
			.inner_call_box{
				height: 45px !important;
			}
			.btn_call_box{
				margin-top: 10px;
				width: 100%;
			}
		}
	}
}

.collage_web{
	display: none;
}
.collage_mob{
	margin-top: 4rem !important;
	display: block !important;
	// .collage_single_img{
	// 	width: 48vw;
	// 	height: 40vh;
	// 	object-fit: cover;
	// 	border-radius: 5px;
	// }
	// .collage_group_img{
	// 	object-fit: cover;
	// 	height: 19vh;
	// 	width: 36vw;
	// 	border-radius: 5px;
	// 	}
}
}

@media screen and (max-width:2048px) and (min-width:1700px) {
	.main-page{

		.primary_size ,.prod_title_description,.useCase_subtitle  {
			font-size: 1.4rem  !important;
		}
		.prod_subText{
			font-size: 1.6rem !important;
		}
		.case_card_text,.pord_description,.benifit_description  {
			font-size: 1.2rem !important;
		}
		.benifit_title,.work_subTitle {
			font-size: 1.3rem !important;
		}
		.custom_menu {
			.active_item:after{
				border-bottom: 4px solid #5571ED !important;
			}
		}
	}
}

@media screen and (max-width: 1440px){
	.main-page{

		.title_box {
			.title_text {
				font-size: 3.5rem;
			}
		}

		// .image_collage{
			
		// }
		.useCase_box {
			.useCase_title {
				font-size: 2.5rem;
			}
			.custom_segment {
				.ant-segmented-item{
					height: 40px;
					// .ant-segmented-item-label{
					// 	font-size: 1rem !important;

					// }
				}
			}
		}
		.clients_box {
			.logo_cards{
				height: 9vh;
				width: 11vw;
			}
		}
		.product_box{ 
			.prod_col{ 
				// height: 70vh;
				.prod_gif{
					height: 44vh;
				}
		}}
	}
	.call_box {
		.ant-row{background-size: auto !important;}
	}

}


@media screen and (max-width: 1378px){
	.main-page{
		.custom_segment {
			.ant-segmented-item{
				height: 35px;
				.ant-segmented-item-label{
					font-size: 0.9rem !important;

				}
			}
		}
		.title_box {
			.title_text {
				font-size: 3rem;
			}
		}
			.prod_subText {
				font-size: 1rem !important;
			}
		.partner_title {
			font-size: 2.5rem !important;
		}
		.partner_subTitle {
			font-size: 2rem;
		}
		.benifit_title {
			font-size: 0.85rem !important;
		}
		.benifit_description{
			font-size: 0.8rem !important;
		}
		
		.prod_brands {
			img{
				height: 2vh;
			}
			span{
				font-size: 0.6rem;
			}
		}

		.clients_box {
			.globe_text {
				font-size: 1.25rem;
			}
			.logo_cards {
				width: 12vw;
				.logo_imgs{
					height: 4vh;
					object-fit: contain;
					display: block;
					max-width: 8vw;
				}
			}
		}

		.useCase_box {
			.useCase_title {
				font-size: 2rem;
			}
		}
		.call_box_title{
			font-size: 2rem !important;
		}

	}

}

@media screen and (max-width: 760px){
	.title_box {
		margin-top: 2rem;
		.try_btn{
			width: 90% !important;
			font-size: 0.9rem;
			img{
				height: 10px;
			}
			
		}
	}
	.product_box{
		.underProd_box{
			width: 100% !important;
			
		}
	}
	.prod_col{
		height: 55vh !important;
		 .prod_col_card{
			width: 100% !important;
		 }
		}
		.case_row{
			row-gap: 25px !important;
			 .ant-col{
				width: 100%;
				height: 30vh !important;
				.ant-card-body{
					padding: 15px !important;
				}
			 }
			 .num_circle{
				height: 45px !important;
				width: 45px !important;
				font-size: 1.5rem !important;
			 }
			//  .case_card_text{
			// 	font-size: 1rem;
			//  }
			}
			.custom_menu{
				flex-direction: row;
				overflow-x: auto;
				gap: 20px;
				padding-left: 20px !important;
				li{
					&:hover{
						border-bottom: none;
						// text-decoration: underline;
					}
					padding: 5px 0;
					img{
						height: 15px;
					}
				}
				.active_item:after{
					top: 24px;
				}
			}
			.work_header_cont{
				flex-direction: column;
				gap: 6px;
				width: 95%;
				.work_subTitle{
					width: 100%;
				}
				.x_imgs{
					align-self: flex-end;
				}
			}
}