.fifth_fold_v1 {
    .container {
        .fifth_wrapper {
            position: relative;
            margin-top: -100px;
            padding-bottom: 80px;

            .image_block {
                max-width: 734px;
                margin: 0 auto;
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width:1200px) {
    .fifth_fold_v1 {
        .container {
            .fifth_wrapper {
                .image_block {
                    max-width: 734px;
                    padding:0 20px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:600px) {
    .fifth_fold_v1 {
        .container {
            .fifth_wrapper {
                margin-top: -100px;
                .image_block {
                    max-width: 100%;
                    padding: 0 20px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}